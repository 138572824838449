import { INVITATON_TYPES } from '~/constants/invitationType';
import { SSO_PLATFORMS } from '~/constants/ssoPlatforms';

export const setButtonHeapTrackId = (
  action: string,
  isInvitation?: boolean,
  platform?: string,
  ssoPlatform?: SSO_PLATFORMS,
  invitationType?: INVITATON_TYPES
) => {
  if (ssoPlatform) {
    return isInvitation
      ? `sso-${platform}${action}-${invitationType}-${ssoPlatform}button`
      : `sso-${platform}${action}-${ssoPlatform}button`;
  }
  return isInvitation
    ? `sso-${platform}${action}-${invitationType}-${action}button`
    : `sso-${platform}${action}-${action}button`;
};
