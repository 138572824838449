import * as React from 'react';

import { LazyImage } from '~/components/LazyImage';

export function FoamImage() {
  return (
    <LazyImage
      height={{ base: 'auto', lg: '100%' }}
      imageProps={{
        alt: '',
        objectPosition: '50% 0%',
        src: '/static/images/foam-login/backgroundMain.jpg',
        sizes: '(max-width: 640px) 720w, (max-width: 992px) 1440w, 2160w',
        srcSet:
          '/static/images/foam-login/backgroundMain.jpg 720w, /static/images/foam-login/backgroundMain@x2.jpg 1440w, /static/images/foam-login/backgroundMain@x3.jpg 2160w',
      }}
      src={'/images/Maabcdgiknnoru.jpg'}
      objectFit={{ base: 'none', md: 'cover' }}
      objectPosition="center center"
      width="100%"
    />
  );
}
