import { AspectRatio, Image, ImageProps } from '@chakra-ui/react';

import { useLazyLoad } from '~/hooks/useLazyLoad';

interface LazyImageProps extends ImageProps {
  fallbackSrc?: string;
  imageProps: object;
  ratio?: number;
  src: string;
}

export const LazyImage: React.FC<LazyImageProps> = ({
  src,
  fallbackSrc,
  ratio = 1,
  imageProps = {},
  ...aspectRatioProps
}) => {
  const {
    imageData,
    onError,
    onLoad,
    ref,
    src: imageSrc,
    styles,
  } = useLazyLoad({
    fallbackSrc,
    src,
  });
  return (
    <AspectRatio ratio={ratio} {...aspectRatioProps}>
      <Image
        fit="cover"
        fontSize={0} // Hide Alt Text on image load on Firefox
        height="100%"
        onError={onError}
        onLoad={onLoad}
        ref={ref}
        src={imageSrc}
        width="100%"
        alt=""
        {...styles}
        {...{ ...imageProps, ...imageData }}
      />
    </AspectRatio>
  );
};
