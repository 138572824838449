import { Box, Flex, Grid } from '@chakra-ui/react';
import React from 'react';

import { Alert } from '~/components/Alert';
import { AuthorizationFooter } from '~/components/AuthorizationFooter';
import { PLATFORMS } from '~/constants/platforms';

import { FoamImage } from './components/FoamImage';
import { WhalarImage } from './components/WhalarImage';
interface LayoutProps {
  platform: PLATFORMS;
  children: React.ReactNode;
}

export function LoginLayout({ children, platform }: LayoutProps) {
  return (
    <Grid
      gridAutoColumns={{ base: '1fr', lg: '1fr 1fr' }}
      templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
      height="100vh"
      overflow="hidden"
    >
      <Box
        display={{ base: 'none', lg: 'block' }}
        flex="1"
        bg="#F6993C"
        color="white"
        position="relative"
        overflow="hidden"
      >
        {platform === PLATFORMS.foam ? <FoamImage /> : <WhalarImage />}
      </Box>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        maxW={{ base: '100vw', lg: '50vw' }}
        paddingTop={{ base: 8, lg: 4 }}
        height="100vh"
        overflow="auto"
      >
        <Flex flexFlow="column" paddingTop={{ base: 0, lg: 10 }}>
          <Alert />
          <Flex
            flexWrap="wrap"
            flexDirection="column"
            marginX="auto"
            px={{ base: 0, lg: 1 }}
            paddingTop={{ base: 8, lg: 8 }}
            width={{ base: 270, sm: 360, md: 400, lg: 360 }}
          >
            {children}
          </Flex>
        </Flex>
        <AuthorizationFooter platform={platform} />
      </Flex>
    </Grid>
  );
}
