import { useEffect, useRef, useState, LegacyRef } from 'react';

import { PIXEL_IMAGE } from '~/constants/pixelimage';
import theme from '~/styles/theme';

import { useInViewport } from './useInViewport';

type LazyLoadProps = {
  fallbackSrc: string | undefined;
  src: string | undefined;
};

type useLazyLoadTypes = {
  imageData: object;
  onError: () => any;
  onLoad: () => any;
  ref: LegacyRef<HTMLImageElement> | undefined;
  src: string | undefined;
  styles: object;
};

export const useLazyLoad = ({
  fallbackSrc,
  src,
}: LazyLoadProps): useLazyLoadTypes => {
  const [componentSrc, setComponentSrc] = useState<string | undefined>('');
  const [imageData, setImageData] = useState<object>({ 'data-loading': '' });
  const [styles, setStyles] = useState(theme.lazyLoad.Loading);
  const componentRef = useRef<HTMLImageElement>(null);
  const { isInView } = useInViewport(componentRef);

  // Lazy load component
  useEffect(() => {
    if (isInView && componentSrc !== src) {
      setComponentSrc(src);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, isInView]);

  const onError = () => {
    if (!isInView) {
      return;
    }

    if (componentSrc === src && fallbackSrc) {
      setComponentSrc(fallbackSrc);
      setStyles({});
    } else if (!fallbackSrc || componentSrc === fallbackSrc) {
      setComponentSrc(PIXEL_IMAGE);
      setStyles(theme.lazyLoad.Error);
    }
    setImageData({ 'data-placeholder': '' });
  };

  const onLoad = () => {
    if (componentSrc === src) {
      setStyles({});
      setImageData({});
    }
  };

  return {
    imageData,
    onError,
    onLoad,
    ref: componentRef,
    src: componentSrc,
    styles,
  };
};
