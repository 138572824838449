import { random } from 'lodash';
import { useMemo } from 'react';

import { LazyImage } from '~/components/LazyImage';

export function WhalarImage() {
  const getLogInImage = useMemo(() => {
    const randomImg = random(1, 7);
    const url = `/static/images/whalar/${randomImg}.jpg`;
    return url;
  }, []);

  return (
    <LazyImage
      height={{ base: 'auto', lg: '100%' }}
      imageProps={{
        alt: '',
        objectPosition: '50% 0%',
      }}
      src={getLogInImage}
      objectFit={{ base: 'none', md: 'cover' }}
      objectPosition="center center"
      width="100%"
    />
  );
}
