import { RefObject } from 'react';
import { useIntersection } from 'react-use';

const defaultOptions = {
  root: null,
  rootMargin: '75%',
  threshold: 0.01,
};

type useInViewportTypes = {
  isInView: boolean | undefined;
};

export function useInViewport(
  ref: RefObject<HTMLElement>,
  customOptions = {}
): useInViewportTypes {
  const options = {
    ...defaultOptions,
    ...customOptions,
  };

  const { root, rootMargin, threshold } = options;

  const intersecting = useIntersection(ref, {
    root,
    rootMargin,
    threshold,
  });

  return { isInView: intersecting?.isIntersecting || false };
}
